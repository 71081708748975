import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { registrationform } from '../../formsConfig/formsConfig';
import { Button, SmallLink, Input, Loader, Modal, Typography, Flex, Text } from '../Ui';
import ErrorInModal from '../ErrorInModal';
import useForm from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { respondTo } from '../../theme/mixin';
import AuthContentWrapper from '../AuthContentWrapper/AuthContentWrapper';
import { Link, useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
const Container = styled.div`
  label {
    color: ${({ theme }) => theme.colore_testo_box};
    a {
      text-decoration: underline;
    }
  }
  a {
    text-decoration: underline;
    color: #000;
  }
  /*   label input:checked + .checkmark {
    background-color: ${({ theme }) => theme.default_color};
  }
  input[type='checkbox']:checked + label::before {
    background-color: ${({ theme }) => theme.default_color};
  } */
`;
const CustomButton = styled(Button)`
  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const RegisterForm = props => {
  const {
    signUpInit,
    loadingSignup,
    signup,
    signupSuccessMessage,
    clearError,
    error,
    lang,
    own_login_adult,
    fields,
    extrafields,
    gdpr,
    codefield: { label, visible, required, regex },
    notes,
    code: appCode,
    data,
  } = props;
  const history = useHistory();
  const { registration_start, registration_end } = useSelector(
    state => state?.app?.config?.settings || {},
  );
  const now = moment();
  const start_registration = moment(registration_start, 'YYYY-MM-DD HH:mm:ss') || now;
  const end_registration = moment(registration_end, 'YYYY-MM-DD HH:mm:ss') || now;
  const isRegistrationOpen = now.isBetween(start_registration, end_registration, null, '[]');
  const [step, setStep] = useState(1);
  const [clickDisabled, setClickDisabled] = useState(false);
  const { accetta_regolamento_concorso } = useSelector(
    state => state?.app?.config?.ownlogin?.gdpr || {},
  );
  const dispatch = useDispatch();
  let code = {};
  if (visible) {
    code = {
      code: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          name: 'code',
          id: 'code',
          placeholder: '',
        },
        label: `${label}`,
        value: '',
        validation: {
          isCustomCode: regex ? true : false,
          regex: regex,
          required: required,
          touched: false,
        },
        valid: !required,
        labelColorSecondary: true,
      },
    };
  }
  let objFields = {};
  fields.forEach(item => {
    objFields[item.name] = item;
  });

  const [showModal, setShowModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);

  const [showHint, setShowHint] = useState();
  const [t] = useTranslation();
  const inputRef = useRef();
  const registerCMS = useSelector(state => state.cms.base).find(c => c.name === 'Testi generali');

  const titleFormRegister = registerCMS?.contents?.find(
    c => c.name === 'Intestazione Registrazione',
  )?.i18l?.title;
  const subtitleFormRegister = registerCMS?.contents?.find(
    c => c.name === 'Intestazione Registrazione',
  )?.i18l?.content;
  const scrollRef = useRef();
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);
  const registerForm = registrationform(
    fields.find(f => f.name === 'email')?.label || t('auth.email'),
    fields.find(f => f.name === 'firstname')?.label || t('auth.firstname'),
    fields.find(f => f.name === 'lastname')?.label || t('auth.lastname'),
    fields.find(f => f.name === 'username')?.label || t('auth.username'),
    fields.find(f => f.name === 'password')?.label || t('auth.password'),
    fields.find(f => f.name === 'repeatpassword')?.label || t('auth.repeatpassword'),
    fields.find(f => f.name === 'dateofbirth')?.label || t('auth.dateofbirth'),
    fields.find(f => f.name === 'sex')?.label || t('auth.sex'),
    t('auth.male'),
    t('auth.female'),
    t('auth.pswerror'),
    t('auth.pswconfirmerror'),
    inputRef,
    gdpr,
    t('gdpr.accept'),
    t('gdpr.reject'),
    objFields,
    appCode,
    data || JSON.parse(localStorage.getItem('clientVerification') || '{}'),
  );

  const registerFormfiltered = Object.keys(registerForm)
    .filter(key => fields.find(item => item.name === key))
    .reduce((obj, key) => {
      obj[key] = registerForm[key];
      return obj;
    }, {});

  const finalForm = { ...registerFormfiltered, ...code };

  const register = () => {
    const data = {};

    if (visible) {
      fields.push('code');
    }

    for (const property in formData) {
      data[property] =
        property !== 'dob' ? formData[property].value : formData[property].valuetosend;
    }

    const fieldsData = Object.keys(data)
      .filter(key => fields.find(item => item.name === key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const otherFields = Object.keys(data)
      .filter(key => !fields.find(item => item.name === key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const dataToSend = {
      ...fieldsData,
    };
    !loadingSignup && signUpInit(dataToSend);
    setClickDisabled(true);
  };

  const modalHandler = () => {
    setShowModal(!showModal);
    clearError();
  };

  const signupModalHandler = () => {
    setSignupModal(prev => !prev);
    dispatch(actionCreators.signupSuccessClear());
    history.push('/auth/login');
  };

  useEffect(() => {
    if (error !== null) {
      if (error) {
        setShowModal(true);
      }
    }
    return () => {};
  }, [error]);

  useEffect(() => {
    if (signup) {
      setSignupModal(true);
    }
  }, [signup]);
  let datePickerNames = [];

  for (var prop in finalForm) {
    if (finalForm[prop].elementType === 'datepicker') {
      datePickerNames.push(finalForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    countries,
    districts,
    cities,
    zipcode,
    formIsValid,
    setFormData,
  } = useForm(register, finalForm, datePickerNames, null, null, null, null);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  //for hint
  const clickHintHandler = hint => {
    setShowHint(hint);
  };
  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation?.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={date => datePickerHandler(date, inp.name)}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        own_login_adult={own_login_adult}
        radioInputs={inp.inputs}
        districts={districts}
        countries={countries}
        cities={cities}
        zipcode={zipcode}
        clickHintHandler={clickHintHandler}
        form={formData}
        setForm={setFormData}
        {...inp}
      />
    );
  });
  return (
    <Container ref={scrollRef}>
      <Text as='div' bold align='center' size='40'>
        {titleFormRegister}
      </Text>

      {isRegistrationOpen ? (
        <form onSubmit={formSubmitHandler}>
          <Text as='div' align='center' size='12' margin='10px 0 40px 0'>
            {subtitleFormRegister}
          </Text>
          <AuthContentWrapper navActive='register'>
            <InputsWrapper>
              {inputs.filter(item => item.props.elementType !== 'inputcheckbox')}
            </InputsWrapper>
            <Text margin='20px 0 30px' size={10}>
              * Campo obbligatorio
            </Text>
            {inputs.filter(item => item.props.elementType === 'inputcheckbox')}
            <Text margin='15px 0 30px' size={10}>
              * Flag obbligatori
            </Text>
          </AuthContentWrapper>
          <Flex
            justify='center'
            align='center'
            direction='column'
            style={{ marginTop: '45px', marginBottom: '30px' }}
          >
            <Button
              white
              upper
              bold
              width='220px'
              loading={loadingSignup}
              style={{ justifyContent: 'center', marginBottom: '25px' }}
              active
            >
              {t('auth.registerlabel')}
            </Button>
            <Text size={14} as='p'>
              Sei già registrato? <Link to='/auth/login'>ACCEDI</Link>
            </Text>
          </Flex>
        </form>
      ) : (
        <div className='no-registration-text'>
          <Text align={'center'} size={20} margin='20px'>
            Non è più possibile registrarsi al sito. Per altre informazioni consulta il{' '}
            <a href={accetta_regolamento_concorso?.pdf} className='item' target='_blank'>
              regolamento
            </a>
            .
          </Text>
        </div>
      )}

      <Modal alert show={signupModal} close={signupModalHandler}>
        {signupSuccessMessage ||
          "Ti abbiamo mandato un'email, clicca sul link per confermare la registrazione, controlla anche la casella spam."}
      </Modal>
      <Modal show={showHint} close={() => setShowHint()}>
        <Text>{showHint}</Text>
      </Modal>
      <Modal alert show={showModal} close={modalHandler} type='error'>
        <ErrorInModal error={error} fromTranslation={typeof error === 'number' ? true : false} />
      </Modal>
    </Container>
  );
};

const InputsWrapper = styled.div`
  overflow: auto;
  display: grid;
  ${respondTo.sm`
    grid-template-columns: 1fr 1fr;
  `}
  gap: 15px;

  margin: 0 auto;

  & > * {
    margin-bottom: 0;
  }
`;

/* const ProgressDots = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;

  & > div {
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #dce4e8;

    &.active {
      background-color: ${props => props.theme.primary};
    }
  }
`; */

const mapStateToProps = state => {
  return {
    loadingSignup: state.auth.loadingSignup,
    signup: state.auth.signup,
    signupSuccessMessage: state.auth.signupSuccessMessage,
    error: state.auth.errorSignup,
    lang: state.app.config.lang,
    own_login_adult: state.app.config.ownlogin.adult,
    fields: state.app.config.ownlogin.fields,
    codefield: state.app.config.ownlogin.code,
    code: state.app.config.code,
    extrafields: state.app.config.ownlogin.extrafields,
    notes: state.app.config.ownlogin.notes,
    gdpr: state.app.config.ownlogin.gdpr,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUpInit: data => dispatch(actionCreators.signUpInit(data)),
    clearError: () => dispatch(actionCreators.signupClearError()),
  };
};

RegisterForm.propTypes = {
  signUpInit: PropTypes.func,
  loadingSignup: PropTypes.bool,
  signup: PropTypes.bool,
  clearError: PropTypes.func,
  error: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
