import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text, Flex, Button } from '../Ui';
import { Link } from 'react-router-dom';
import img_mobile from '../../../src/assets/images/SantoroBatman_visore_mobile.png';
const CmsComponentContainer = styled.div`
  background-color: ${props => props.background_color ? props.background_color : 'transparent'};
  .text-box, .text-box a {
    color: ${props => props.text_color ? props.text_color : props.theme.default_color};
  }
  .banner {
    width: 100%;
  }
  .link {
    padding-bottom: 80px;
    button {
      background-color: ${props => props.text_color ? props.text_color : props.theme.default_color};
      color: ${props => props.background_color ? props.background_color : 'transparent'};
    }
  }
  
`;

const CmsComponent = ({content}) => {
  const scrollRef = useRef();
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);
  const ctaUrl = content?.cta_url?.split(';');
  const ctaLabel = content?.cta_label?.split(';');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CmsComponentContainer {...content}>
        {content?.img && <img src={isMobile ? img_mobile : content?.img} className='banner' alt='' />}
        {content?.i18l?.title && <Text className="text-box">{content?.i18l?.title}</Text>}
        {content?.i18l?.content && <Text className="text-box">{content?.i18l?.content}</Text>}
        {Boolean(content?.show_cta) && (
          <Flex gap='30px' justify='center' className='link'>
            {ctaUrl.map((item, i) => (
              <Link to={item}>
                <Button active={i === 0} secondary={i !== 0}>
                  {ctaLabel[i]}
                </Button>
              </Link>
            ))}
          </Flex>
        )}
        <div ref={scrollRef}></div>
    </CmsComponentContainer>
  );
};

export default CmsComponent;
